import React from "react";
import theme from "theme";
import { Theme, Text, Section, Box, Image, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, StackItem, Stack } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				El Sanatları Sanatı, Her Seferinde Tek Mürekkep
			</title>
			<meta name={"description"} content={"InkFusion Tattoo Studio'da, benzersiz tarzınıza ve tercihlerinize hitap eden çok çeşitli, yüksek kaliteli dövme hizmetleri sunmaktan gurur duyuyoruz."} />
			<meta property={"og:title"} content={"El Sanatları Sanatı, Her Seferinde Tek Mürekkep"} />
			<meta property={"og:description"} content={"InkFusion Tattoo Studio'da, benzersiz tarzınıza ve tercihlerinize hitap eden çok çeşitli, yüksek kaliteli dövme hizmetleri sunmaktan gurur duyuyoruz."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6661aa1f1d712a0023348f11/images/2-4.jpg?v=2024-06-06T12:24:03.531Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6661aa1f1d712a0023348f11/images/2-4.jpg?v=2024-06-06T12:24:03.531Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6661aa1f1d712a0023348f11/images/2-4.jpg?v=2024-06-06T12:24:03.531Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6661aa1f1d712a0023348f11/images/2-4.jpg?v=2024-06-06T12:24:03.531Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6661aa1f1d712a0023348f11/images/2-4.jpg?v=2024-06-06T12:24:03.531Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6661aa1f1d712a0023348f11/images/2-4.jpg?v=2024-06-06T12:24:03.531Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6661aa1f1d712a0023348f11/images/2-4.jpg?v=2024-06-06T12:24:03.531Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header>
			<Override slot="text" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
		</Components.Header>
		<Section
			background="linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://uploads.quarkly.io/6661aa1f1d712a0023348f11/images/2-4.jpg?v=2024-06-06T12:24:03.531Z) center/cover"
			padding="64px 0"
			sm-padding="40px 0"
			color="--light"
			font="--base"
		>
			<Stack>
				<StackItem width="75%" lg-width="100%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Text as="h1" font="--headline1" md-font="--headline2" margin="10px 0">
						InkFusion Tattoo Studio'ya hoş geldiniz
					</Text>
				</StackItem>
			</Stack>
		</Section>
		<Section padding="60px 0 60px 0" md-padding="30px 0 30px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				padding="0px 90px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				width="50%"
				lg-padding="0px 28px 0px 0px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				align-self="center"
			>
				<Text
					color="--light"
					font="--lead"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 16px 0px"
					margin="0px 0px 0px 0px"
				>
					InkFusion Tattoo Studio'da vücudunuzun bir tuval olduğuna ve her dövmenin benzersiz bir hikaye anlattığına inanıyoruz. Misyonumuz, yaratıcılığı, hassasiyeti ve tutkuyu birleştirerek vizyonunuzu bir şahesere dönüştürmektir. İster ilk kez dövme yapıyor olun, ister tecrübeli bir dövme tutkunu olun, yetenekli sanatçılarımız size sonsuza kadar seveceğiniz olağanüstü bir deneyim sunmak için buradalar.
				</Text>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
			>
				<Image
					src="https://uploads.quarkly.io/6661aa1f1d712a0023348f11/images/2-5%20%281%29.jpg?v=2024-06-06T12:24:03.561Z"
					width="100%"
					max-height="100%"
					object-fit="cover"
					lg-min-height="300px"
					md-max-height="277px"
					md-min-height="16px"
					srcSet="https://smartuploads.quarkly.io/6661aa1f1d712a0023348f11/images/2-5%20%281%29.jpg?v=2024-06-06T12%3A24%3A03.561Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6661aa1f1d712a0023348f11/images/2-5%20%281%29.jpg?v=2024-06-06T12%3A24%3A03.561Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6661aa1f1d712a0023348f11/images/2-5%20%281%29.jpg?v=2024-06-06T12%3A24%3A03.561Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6661aa1f1d712a0023348f11/images/2-5%20%281%29.jpg?v=2024-06-06T12%3A24%3A03.561Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6661aa1f1d712a0023348f11/images/2-5%20%281%29.jpg?v=2024-06-06T12%3A24%3A03.561Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6661aa1f1d712a0023348f11/images/2-5%20%281%29.jpg?v=2024-06-06T12%3A24%3A03.561Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6661aa1f1d712a0023348f11/images/2-5%20%281%29.jpg?v=2024-06-06T12%3A24%3A03.561Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="36px 0 60px 0" quarkly-title="HeroBlock" md-padding="16px 0 30px 0" background="--color-darkL1">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="30%"
				md-width="100%"
				font="--headline3"
			>
				<Text
					font="--headline3"
					color="--light"
					margin="0px 0px 0px 0px"
					lg-font="normal 700 50px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 700 40px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-margin="0px 0px 24px 0px"
				>
					InkFusion Tattoo Studio Hakkında
				</Text>
			</Box>
			<Box
				empty-border-color="LightGray"
				width="70%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
			>
				<Text
					text-align="left"
					lg-font="normal 600 16px/1.5 &quot;Varta&quot;, sans-serif"
					lg-margin="80px 0px 0px 0px"
					md-margin="0px 0px 0px 0px"
					margin="100px 0px 0px 0px"
					color="--light"
					font="--headline5"
				>
					InkFusion Tattoo Studio kendini dövme sanatına adamıştır. Yetenekli profesyonel sanatçılardan oluşan ekibimiz, geniş bir stil yelpazesinde uzmanlaşmış olup, her fikri çarpıcı ayrıntılar ve sanatla hayata geçirebilmemizi sağlar. Karmaşık siyah ve gri tasarımlardan canlı, tam renkli parçalara kadar sizin için mükemmel dövmeyi yaratacak uzmanlığa sahibiz. Stüdyomuz, hijyen, güvenlik ve müşteri memnuniyetinin en önemli önceliklerimiz olduğu yaratıcılık için bir sığınaktır.
				</Text>
			</Box>
		</Section>
		<Section padding="40px 0 0 0">
			<Text margin="0px 0px 0 0px" color="#ffffff" font="--headline2" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
				Neden InkFusion Tattoo Studio'yu Seçmelisiniz?
			</Text>
		</Section>
		<Section padding="40px 0 40px 0" md-padding="20px 0 12px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-border-color="LightGray"
				padding="0px 20px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				empty-min-width="64px"
				empty-border-style="solid"
				width="50%"
				empty-min-height="64px"
				empty-border-width="1px"
			>
				<Text
					sm-margin="24px 0px 16px 0px"
					margin="32px 0px 16px 0px"
					font="--lead"
					color="--green"
					lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="12px 0px 16px 0px"
				>
					Olağanüstü Sanatçılar
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--light"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 32px 0px"
				>
					Ekibimiz, zanaatlarına tutkuyla bağlı, deneyimli ve son derece yetenekli dövme sanatçılarından oluşmaktadır. Her sanatçı, müşterilerimize çok çeşitli seçenekler sunarak kendi benzersiz tarzını ve bakış açısını getiriyor.
				</Text>
			</Box>
			<Box
				empty-border-color="LightGray"
				padding="0px 20px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				empty-min-width="64px"
				empty-border-style="solid"
				width="50%"
				empty-min-height="64px"
				empty-border-width="1px"
			>
				<Text
					sm-margin="24px 0px 16px 0px"
					margin="32px 0px 16px 0px"
					font="--lead"
					color="--green"
					lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="12px 0px 16px 0px"
				>
					Steril Ortam
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--light"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 32px 0px"
				>
					En son teknolojiye sahip ekipman ve sterilizasyon tekniklerini kullanarak en yüksek hijyen ve güvenlik standartlarına uyuyoruz. Sağlığınız ve refahınız bizim en büyük önceliğimizdir.
				</Text>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				width="50%"
				md-width="100%"
				md-padding="0px 0px 0px 0px"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-color="LightGray"
				padding="0px 0px 0px 20px"
			>
				<Text
					margin="32px 0px 16px 0px"
					font="--lead"
					color="--green"
					lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="12px 0px 16px 0px"
					sm-margin="24px 0px 16px 0px"
				>
					Özel Tasarımlar
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--light"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					text-align="left"
				>
					Her dövmenin, onu giyen kişi kadar benzersiz olması gerektiğine inanıyoruz. Sanatçılarımız kişiliğinizi ve hikayenizi yansıtan özel tasarımlar yaratmak için sizinle yakın işbirliği içinde çalışır.
				</Text>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				width="50%"
				md-width="100%"
				md-padding="0px 0px 0px 0px"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-color="LightGray"
				padding="0px 0px 0px 20px"
			>
				<Text
					margin="32px 0px 16px 0px"
					font="--lead"
					color="--green"
					lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="12px 0px 16px 0px"
					sm-margin="24px 0px 16px 0px"
				>
					Konforlu Atmosfer
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--light"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					text-align="left"
				>
					Stüdyomuz kendinizi rahat hissetmeniz için tasarlanmıştır. Rahat ve misafirperver bir ortamla dövme deneyiminizin mümkün olduğu kadar konforlu olmasını sağlıyoruz.
				</Text>
			</Box>
		</Section>
		<Section padding="36px 0 60px 0" quarkly-title="HeroBlock" md-padding="16px 0 30px 0" background="--color-darkL1">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="30%"
				md-width="100%"
				font="--headline3"
				order="1"
			>
				<Text
					font="--headline3"
					color="--light"
					margin="0px 0px 0px 0px"
					lg-font="normal 700 50px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 700 40px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-margin="0px 0px 24px 0px"
				>
					Uzmanlığımız ve Vizyonumuz
				</Text>
			</Box>
			<Box
				empty-border-color="LightGray"
				width="70%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
			>
				<Text
					text-align="left"
					lg-font="normal 600 16px/1.5 &quot;Varta&quot;, sans-serif"
					lg-margin="80px 0px 0px 0px"
					md-margin="0px 0px 0px 0px"
					margin="100px 0px 0px 0px"
					color="--light"
					font="--headline5"
				>
					InkFusion olarak beklentilerinizi aşan yüksek kaliteli dövmeler sunmaktan gurur duyuyoruz. Sanatçılarımız sürekli olarak becerilerini geliştiriyor ve dövme endüstrisindeki en son teknikler ve trendler konusunda güncel kalıyor. Mükemmelliğe olan bu bağlılığımız, size son teknoloji tasarımlar ve gerçekten kişiselleştirilmiş bir deneyim sunmamıza olanak tanır. İster net bir fikirle gelin, ister özel bir tasarım oluşturma konusunda rehberliğe ihtiyacınız olsun, sanatçılarımız yolun her adımında sizinle işbirliği yapmak için buradalar.
				</Text>
			</Box>
		</Section>
		<Section
			padding="40px 0 40px 0"
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://uploads.quarkly.io/6661aa1f1d712a0023348f11/images/2-1.jpg?v=2024-06-06T12:24:03.555Z) center center/cover no-repeat"
			min-height="40vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					Mürekkeplenmeye Hazır mısınız?
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
				>
					InkFusion Tattoo Studio'yu ziyaret ederek yeni dövmenize doğru ilk adımı atın. Yetenekli sanatçılarımız sizinle çalışmaktan ve ömür boyu gururla taşıyacağınız bir sanat eseri yaratmaktan heyecan duyuyor. Randevunuzu bugün alın ve içinizdeki sanatçılığı ortaya çıkarmanıza yardımcı olalım.
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--dark"
					background="--color-secondary"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 0px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-orange"
					sm-margin="0px 0px 0px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s ease-in-out 0s"
				>
					Kişiler
				</Link>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="text" />
			<Override slot="text2" />
			<Override slot="text3" />
			<Override slot="text1" />
			<Override slot="box2" />
			<Override slot="box1" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2">
				Kişiler
			</Override>
			<Override slot="box3" />
			<Override slot="box" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});